/*
*	Gebruiksaanwijzingen webshop
*
*	Toevoegen winkelmandje:
*	data-webshop-action="add-item"
*	data-item-id="[itemid]"
*	data-item-amount="[aantal toe te voegen items]"

*	Verwijderen uit winkelmandje:
*	data-webshop-action="remove-item"
*	data-item-id="[itemid]"
*	data-item-amount="[aantal te verwijderen items]"
*
*	Winkelmandje weergeven:
*	data-webshop-component="basket-amount"
*	data-webshop-component="basket-total"
*
*	data-webshop-component="basket-dropdown"
*
*		data-webshop-component="basket-dropdown-list"
*		-> Onder dit element een element met klasse "template-row" en "display:none" geplaatst
*			Dit element wordt dan gebruikt als basis voor product
*
*			Placeholders voor template-row:
*			- [[product_title]]
*			- [[product_amount]]
*			- [[product_total_price]]
*			- [[product_price]]
*			- [[product_id]]
*			- [[product_image_url]]
*
*/

$(document).ready(function ()
{
	//Knoppen initialiseren
	init_webshop_actions();

	//Werking checkout initialiseren
	init_checkout_billing();

	//Winkelmandje ophalen
	$.get('/api/v2/'+site_id+'/'+lang+'/webshop/basket/get', function(result){
		fill_basket(result);
	});
});

var webshop_total_price = 0;

function init_checkout_billing(){
	if ($('#billing-address-same').length > 0) {
		$billing_check = $('#billing-address-same');

		if ($billing_check.is(':checked')) {
			$('#ws-billing-address').hide();
			set_country_dropdowns(true);
		}else{
			set_country_dropdowns(false);
		}

		$billing_check.change( function(){
			if ($billing_check.is(':checked')) {
				$('#ws-billing-address').slideUp(150);
				set_country_dropdowns(true);
			} else {
				$('#ws-billing-address').slideDown(150);
				set_country_dropdowns(false);
			}
		});
	}
}

function set_country_dropdowns(checked){
	if (checked) {
		$('[data-webshop-component="country-input"]').attr('disabled', 'disabled');
		$('[data-webshop-component="country-input"]').hide();
		$('[data-webshop-component="country-dropdown"]').attr('disabled', null);
		$('[data-webshop-component="country-dropdown"]').show();
	}else{
		$('[data-webshop-component="country-dropdown"]').attr('disabled', 'disabled');
		$('[data-webshop-component="country-dropdown"]').hide();
		$('[data-webshop-component="country-input"]').attr('disabled', null);
		$('[data-webshop-component="country-input"]').show();
	}
}

function init_webshop_actions(){
	$('[data-webshop-action="add-item"]').off('click', add_product_to_basket);
	$('[data-webshop-action="add-item"]').on('click', add_product_to_basket);
	$('[data-webshop-action="add-order"]').off('click', add_order_to_basket);
	$('[data-webshop-action="add-order"]').on('click', add_order_to_basket);
	$('[data-webshop-action="pay-order-stripe"]').off('click', pay_order_stripe);
	$('[data-webshop-action="pay-order-stripe"]').on('click', pay_order_stripe);
	$('[data-webshop-action="change-payment-type"]').off('change', view_payment_elements);
	$('[data-webshop-action="change-payment-type"]').on('change', view_payment_elements);
	$('[data-webshop-action="change-transactioncost"]').off('change', update_transactioncost);
	$('[data-webshop-action="change-transactioncost"]').on('change', update_transactioncost);
	$('[data-webshop-action="update-amount"]').off('change', update_item_amount);
	$('[data-webshop-action="update-amount"]').on('change', update_item_amount);
}

function update_item_amount(){
	var amount = parseFloat($(this).val());
	var stock = parseFloat($('[data-webshop-component="stock-value"]').html());
	var itemid = $(this).attr('data-item-id');

	if (amount > stock) {
		$('[data-webshop-component="stock-empty"][data-item-id="'+itemid+'"]').show();
	}else{
		$('[data-webshop-component="stock-empty"]').hide();
	}
}

function update_transactioncost(){
	var cost_id = $(this).val();

	$.post('/api/v2/'+site_id+'/'+lang+'/webshop/transaction', {cost_id:cost_id}, function(result){
		fill_basket(result);
	});
}

function view_payment_elements(){
	var input_name = $(this).attr('name');
	var type = $('input[name="'+input_name+'"]:checked').val();
	$('[data-payment-method-button]').hide();
	$('[data-payment-method-button="'+type+'"]').show();

}

function pay_order_stripe(){
	var type = $(this).attr('data-payment-type');
	var user = $(this).attr('data-payment-user');

	var source = {
	  type: type,
	  amount: Math.round(webshop_total_price*100),
	  currency: 'eur',
	  owner: {
	    name: user,
	  },
	  redirect: {
	    return_url: base_url+'/api/v2/'+site_id+'/'+lang+'/webshop/stripe/payment',
	  },
	};

	if (type == 'sofort') {
		source.sofort= {
			country: $(this).attr('data-payment-country'),
		};
	}

	if (type == 'ideal' || type == 'giropay') {
		source.statement_descriptor = $(this).attr('data-payment-descriptor');
	}

	stripe.createSource(source).then(function(result) {
		if (result.source) {
			window.location = result.source.redirect.url;
		}
	});
}

function fill_basket(basket){
	$('[data-webshop-component="basket-dropdown"]').each(function(index, el){
		$(el).find('[data-webshop-component="basket-dropdown-list"]').html('');
		var product_count = 0;
		var total_price = 0;
		if (Object.prototype.toString.call( basket.products ) === '[object Object]') {
			$.each(basket.products, function(id, product){
				$template = $(el).find('.template-row').clone();
				$template.removeClass('template-row');

				$template.html(replaceAll($template.html(),'[[product_title]]', product.item.item_title));
				var product_total = parseFloat(Math.round(product.item.field_price.value*product.amount * 100) / 100);
				$template.html(replaceAll($template.html(),'[[product_total_price]]', (product_total.toFixed(2))));
				if (basket.discount > 0) {
					var original = product_total/((100-basket.discount)/100);
					$template.html(replaceAll($template.html(),'[[product_total_price_old]]', (original.toFixed(2))));
				}
				$template.html(replaceAll($template.html(),'[[product_price]]', parseFloat(Math.round(product.item.field_price.value * 100) / 100).toFixed(2)));
				$template.html(replaceAll($template.html(),'[[product_amount]]', product.amount));
				$template.html(replaceAll($template.html(),'[[product_id]]', product.item.itemid));

				total_price += (product.item.field_price.value*product.amount);

				var image_url = "/placeholder/500x500/ffffff";
				if (product.item.attachments.length > 0) {
					image_url = '/'+product.item.attachments[0].attachment_directory + product.item.attachments[0].attachment_slug;
				}

				$template.html(replaceAll($template.html(),'[[product_image_url]]', image_url));

				$basket_amount_component = $template.find('[data-webshop-action="change-amount"]').val(product.amount);

				$template.find('[data-webshop-action="remove-item"]').on('click', remove_product_from_basket);
				$template.find('[data-webshop-action="change-amount"]').on('change', change_product_amount);

				$template.css('display', '');
				$(el).find('[data-webshop-component="basket-dropdown-list"]').append($template);
				product_count++;

				//Aantal in winkelmandje aftrekken
				var stock_left = product.item.field_stock.value - product.amount;
				console.log(stock_left);
				$stock_select = $('[data-webshop-component="pick-amount"][data-item-id="'+product.item.item_id+'"]');
				if (stock_left > 0) {
					$('[data-webshop-component="stock-empty"][data-item-id="'+product.item.item_id+'"]').hide();
				}else{
					stock_left = 0;
					$('[data-webshop-component="stock-empty"][data-item-id="'+product.item.item_id+'"]').show();
				}

				$('[data-webshop-component="stock-value"][data-item-id="'+product.item.item_id+'"]').html(stock_left);
			});

			var transactioncost = 15;
			var subtotal = Math.round(total_price * 100) / 100;
			var total = (subtotal+transactioncost)*1.21;

			if (basket.discount > 0) {
				var original = subtotal/((100-basket.discount)/100);
				$('[data-webshop-component="basket-discount"]').html(parseFloat(original-subtotal).toFixed(2));
			}
			if (auth_user != null) {
				if (auth_user.country != 'BE') {
					total = (subtotal+transactioncost);
					$('[data-webshop-component="basket-btw-row"]').hide();
				}else{
					var btw = (subtotal+transactioncost)*0.21;
					$('[data-webshop-component="basket-btw"]').html(parseFloat(btw).toFixed(2));
				}
			}

			$('[data-webshop-component="basket-total"]').html(parseFloat(total).toFixed(2));
			$('[data-webshop-component="basket-subtotal"]').html(parseFloat(subtotal).toFixed(2));

			$('[data-webshop-component="basket-amount"]').html(product_count);
			$('[data-webshop-component="basket-transactioncost"]').html(transactioncost.toFixed(2));

			webshop_total_price = parseFloat(total).toFixed(2);
		}
	});

	$('[data-webshop-component="product-actions"]').show();
	init_webshop_actions();
}

function change_product_amount(){
	var itemid = $(this).attr('data-item-id');
	var amount = $(this).val();

	$.get('/api/v2/'+site_id+'/'+lang+'/webshop/basket/update/'+itemid+'/'+amount, function(result){
		fill_basket(result);
	});
}

function add_orderitem_to_basket(id, amount){
	$.get('/api/v2/'+site_id+'/'+lang+'/webshop/basket/add/'+id+'/'+amount, function(result){
		fill_basket(result);
	});
}

function add_order_to_basket(){
	var items_json = $(this).attr('data-items');
	var products = JSON.parse(items_json);
	for (var i = products.length - 1; i >= 0; i--) {
		var product = products[i];
		setTimeout('add_orderitem_to_basket('+product.id+','+ product.amount+')', 100*i);
	}

	var n = noty({
		layout: 'bottomRight',
		theme: 'metroui',
		type: 'information',
		text: add_order_trans,
		timeout: 3000,
		maxVisible: 5,
		animation: {
			open: {height: 'toggle'},
			close: {height: 'toggle'},
			easing: 'swing',
	                speed: 500 // opening & closing animation speed
	            }
	        });
}

function add_product_to_basket(){
	var itemid = $(this).attr('data-item-id');
	var amount = $(this).attr('data-item-amount');

	$.get('/api/v2/'+site_id+'/'+lang+'/webshop/basket/add/'+itemid+'/'+amount, function(result){
		fill_basket(result);
        var n = noty({
            layout: 'bottomRight',
			theme: 'metroui',
            type: 'information',
            text: add_product_trans,
            timeout: 3000,
            maxVisible: 5,
            animation: {
                open: {height: 'toggle'},
                close: {height: 'toggle'},
                easing: 'swing',
                speed: 500 // opening & closing animation speed
            }
        });
	});
}

function remove_product_from_basket(){
	var itemid = $(this).attr('data-item-id');
	var amount = $(this).attr('data-item-amount');

	$.get('/api/v2/'+site_id+'/'+lang+'/webshop/basket/remove/'+itemid+'/'+amount, function(result){
		fill_basket(result);
	});
}

function escapeRegExp(str) {
	if (typeof str != 'undefined') {
		return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
	}else{
		return '';
	}
}

function replaceAll(str, find, replace) {
	if (typeof str != 'undefined') {
		return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
	}else{
		return '';
	}
}
