$(document).ready(function ()
{
	/*
	 *	THUMB SLIDER
	 */

	 $('.custom-select').customSelect();


	// Youtube/Vimeo thumbs
	$("[class*='rc-video'] .thumb").click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			iframe.attr('src', src + '?autoplay=1');
		}
		block.fadeOut(150);
	}


	// Lightbox - UNCOMMENT ME BIJ KOPPELEN
	$('a.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});

});

$(window).load(function(){
	$('#carousel-thumbs').flexslider({
		animation: "slide",
		controlNav: false,
		animationLoop: false,
		slideshow: false,
		itemWidth: 96,
		itemMargin: 0
	});

	$('#carousel-banner').slick({
	  dots: true,
	  infinite: true,
	  speed: 1000,
	  autoplay: true,
	  autoplaySpeed: 5000,
	  fade: true,
	  cssEase: 'linear',
	});
	$(".slick-dots").wrap("<div class=\"container container-dots\"></div>");

	$('#carousel-products').flexslider({
		animation: "slide",
		controlNav: true,
		directionNav: false,
		animationLoop: false,
		slideshow: false,
		itemWidth: 224,
		itemMargin: 0
	});

	$('#carousel-categories').flexslider({
		animation: "slide",
		controlNav: true,
		directionNav: false,
		animationLoop: false,
		slideshow: false,
		itemWidth: 224,
		itemMargin: 0
	});
});